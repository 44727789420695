"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserGeogroup = exports.GeogroupStatement = exports.Geogroup = void 0;
exports.compare = compare;
exports.geogroupTypes = void 0;
const geogroupTypes = exports.geogroupTypes = ['city', 'company', 'association', 'school', 'private', 'other'];
class Geogroup {
  constructor(id, type, areaId, title, code, photo, place, nbMembers, totalDistance, automaticChallengeStatus, offersAccompaniedTrips, sizeRange, parentId, subGroupType, partner, publicationStatus, bannerPicture, description, link, openMembership) {
    this.id = id;
    this.type = type;
    this.areaId = areaId;
    this.title = title;
    this.code = code;
    this.photo = photo;
    this.place = place;
    this.nbMembers = nbMembers;
    this.totalDistance = totalDistance;
    this.automaticChallengeStatus = automaticChallengeStatus;
    this.offersAccompaniedTrips = offersAccompaniedTrips;
    this.sizeRange = sizeRange;
    this.parentId = parentId;
    this.subGroupType = subGroupType;
    this.partner = partner;
    this.publicationStatus = publicationStatus;
    this.bannerPicture = bannerPicture;
    this.description = description;
    this.link = link;
    this.openMembership = openMembership;
  }
  get isPremium() {
    return this.type === 'company' && !!this.partner;
  }
  clone() {
    return new Geogroup(this.id, this.type, this.areaId, this.title, this.code, this.photo, this.place, this.nbMembers, this.totalDistance, this.automaticChallengeStatus, this.offersAccompaniedTrips, this.sizeRange, this.parentId, this.subGroupType, this.partner, this.publicationStatus, this.bannerPicture, this.description, this.link, this.openMembership);
  }
}
exports.Geogroup = Geogroup;
class UserGeogroup {
  constructor(status, group, joinDate, lastActivityDate, stats, automaticChallengeStatus, isAdmin) {
    this.status = status;
    this.group = group;
    this.joinDate = joinDate;
    this.lastActivityDate = lastActivityDate;
    this.stats = stats;
    this.automaticChallengeStatus = automaticChallengeStatus;
    this.isAdmin = isAdmin;
  }
  get isJoined() {
    return this.status === 'automaticallyJoin' || this.status === 'manuallyJoin';
  }
}
exports.UserGeogroup = UserGeogroup;
class GeogroupStatement {
  constructor(id, strDate, distance, nbTraces) {
    this.id = id;
    this.strDate = strDate;
    this.distance = distance;
    this.nbTraces = nbTraces;
    this.date = new Date(strDate);
  }
}
exports.GeogroupStatement = GeogroupStatement;
function compare(aStats, bStats) {
  if (aStats && bStats) {
    return aStats.distance !== bStats.distance ? bStats.distance - aStats.distance : 0;
  }
  if (aStats) return -1;
  if (bStats) return 1;
  return null;
}